<template>
  <div class="outer-page">
    <div class="warpper">
      <article-info></article-info>
      <div class="link-active">
        <ul>
          <li class="qq">
            <a target="_blank"></a>
          </li>
          <li class="tel">
            <a></a>
          </li>
          <li class="message">
            <a></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="clears"></div>
  </div>
</template>

<script>
import articleInfo from "@components/articleInfo";
export default {
  name: "",
  components: {
    articleInfo,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>